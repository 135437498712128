import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Typography} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "../../component/Page/Page";
import OtpButton from "../../component/DarkMode/OtpButton";
import Textfield from "../../component/DarkMode/Textfield";
import Buttons from "../../component/DarkMode/Button";
import CreateIcon from "@mui/icons-material/Create";
import { loginService } from "../../_services/login";
import { RegisterService } from "../../_services/register";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Cookies from "universal-cookie";
import HelperText from "../../component/DarkMode/HelperText";
import { setToken, loginSuccess } from "../../store/bugs";
import SnackBar from "../../component/SnackBar/SnackBar";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Notification from "../../component/AlertModel/Notification";
import Backdrop from '@mui/material/Backdrop';
import { RecaptchaVerifier, signInWithPhoneNumber ,getAuth} from 'firebase/auth';
import { initializeApp,getApps } from "firebase/app";
// import jwt from 'jsonwebtoken';
import DisableRightClick from "../../_helpers/DisableRightClick";

const secretKey = 'unlistedsecratetestingkey';

const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    height: "100vh",
    background: "#0d0d0d",
    position:'relative'
  },
  container: {
    paddingTop: "52px"
  },
  verifyText: {
    color: "#fff",
    paddingBottom:"8px !important",
    left: "calc(50% - 173px/2 - 77.5px)",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "20px !important",
    lineHeight: "30px !important",
  },
  verfySubText: {
    color: "#fff",
    left: "16px",
    top: "135px",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
  },
  loader: {
    color: "#fff !important",
    width: '25px !important',
    height: '25px !important',
  },
  otploader:{
    color: "white !important" ,
    width: '18px !important',
    height: '18px !important',
   }
}));

const OtpPhone = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();
  const [invalidOtpCount, setInvalidOtpCount] = useState(0);
  const [type, setType] = useState(null);
  const isDisabled = true;
  const [open, setOpen] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState();
  const [severity, setSeverity] = useState("");
  const [resendOtpTime, setResendOtpTime] = useState(30);
  const [helperText, setHelperText] = useState(null);
  const [otp, setOtp] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(location != undefined ? location.state != undefined ? location.state.mobile : null : null);
  const [submit, setSubmit] = useState(false);
  const [submitButton, setSubmitButton] = useState(true);
  const [valueColor, setValueColor] = useState();
  const [timer,setTimer] = useState(true)
  const [timerText,setTimerText] = useState("")
 let auth;
  useEffect(() => {
    if(resendOtpTime===0){
      setResendOtpTime(0)
     setTimeout(()=>{
      setTimerText()
     },1000)
    }
    if (!resendOtpTime) return;

    const resendOtpSec = setInterval(() => {
      setResendOtpTime(resendOtpTime - 1);
      setTimerText(`Didn't receive OTP? Retry in 00:${resendOtpTime <10 ? `0${resendOtpTime}` :resendOtpTime}`)
    }, 1000);
    return () => clearInterval(resendOtpSec);
  }, [resendOtpTime]);

  async function initializeFirebae(){
    try {
      let app;
      let firebaseEnv = process.env.REACT_APP_ADMIN_CLIENT.replace(/\\/g, '');
      let firebaseEnvFormated = firebaseEnv.replace(/""/g, '');
      let firebaseEnvFormatedObject = JSON.parse(firebaseEnvFormated)
        if(getApps().length === 0 ){
        app = initializeApp(firebaseEnvFormatedObject.REACT_APP_ADMIN_CLIENT);
      }
      auth = getAuth(app);
    } catch (error) {
     console.log(error);
    } 
   }
  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha',{
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      }
    }, auth);
  }

  const handleOtp = async() => {
    setOtp(true)
    await initializeFirebae()
    if (location.state.type === "login") {
      setMobileNumber(location.state.mobile);
      loginService.mobileAuthentication(mobileNumber).then((res) => {
        setResendOtpTime(30)
        if (res.status === 200) {
          generateRecaptcha();
          let appVerifier = window.recaptchaVerifier;
          signInWithPhoneNumber(auth, mobileNumber, appVerifier)
            .then((confirmationResult) => {
               window.confirmationResult = confirmationResult;
              }).catch((error) => {
                // Error; SMS not sent
                console.log(error);
              });  
          setTimeout(()=> {
            setOtp(false)
          },1000)
          setTimer(true)
          setType("error")
        } else if (res.status === 401) {
          if (res.data.message === "ACCOUNT_BLOCKED") {
            setOtp(false)
            setValueColor(2);
            setSeverity("error");
            let timerMsg = "Account blocked, please try after "+ Math.abs(res.data.time.hour) +" hours " + Math.abs(res.data.time.minute)+" minutes"
            setSnackbarTitle(timerMsg);
            
            setOpen(true);
          }
        } else {
          setOtp(false)
          setOpen(true);
          setSeverity("error");
          setSnackbarTitle(
            "Unable to connect the server, Please try again"
          );
        }
      }).catch(() => {
        setOtp(false)
        setOpen(true);
        setSeverity("error");
        setSnackbarTitle(
          "Unable to connect the server, Please try again"
        );
      })
    } else {
      if (location.state.type === "register") {
        RegisterService.registerMobileNumber(mobileNumber).then(
          (res) => {
            if (res) {
              if (res.status === 200) {
                  generateRecaptcha();
            let appVerifier = window.recaptchaVerifier;
            signInWithPhoneNumber(auth, mobileNumber, appVerifier)
            .then((confirmationResult) => {
               window.confirmationResult = confirmationResult;
              }).catch((error) => {
                // Error; SMS not sent
                console.log(error);
              });  
                setTimeout(()=> {
                  setOtp(false)
                },1000)
                setResendOtpTime(30)
                setTimer(true)
                setType("error")
              }
            } else {
              setOpen(true);
              setOtp(false)
              setSeverity("error");
              setSnackbarTitle(
                "Unable to connect the server, Please try again"
              );
              setOpen(true);
            }
          }
        ).catch(() => {
          setOpen(true);
          setOtp(false)
          setSeverity("error");
          setSnackbarTitle(
            "Unable to connect the server, Please try again"
          );
        })
      }
    }
  };


  const setUserInfo = () => {
    loginService.getLoggedInUserInfo().then(async (res) => {
      if (res) {
        if (res.status === 200) {
          const auth = { loggedIn: true, user: res.data };
          props.loginSuccess({ auth: auth });
        }
      } else {
        setSeverity("error");
        setSnackbarTitle("Unable to connect the server, please try again");
        setOpen(true);
      }
    });
  };


  // const confirmAPi =(()=>{
  //   let confirmationResult = window.confirmationResult;
  //   confirmationResult.confirm(value).then((result,error) => {
  //     return {result,error}
  //   })
  // })
  	
 const handleOtpViaCall = ()=>{
  setOtp(true)
  setHelperText("");
  setType()
    if (location.state.type === "login") {
      setMobileNumber(location.state.mobile);
      loginService.getOtpViaCall(mobileNumber).then((res) => {
        if (res.status === 200) {
          setTimeout(()=> {
            setOtp(false)
          },1000)
          setResendOtpTime(30)
          setTimer(true)
          setType("error")
        } else if (res.status === 401) {
          if (res.data.message === "ACCOUNT_BLOCKED") {
            setOtp(false)
            setValueColor(2);
            setSeverity("error");
            let timerMsg = "Account blocked, please try after "+ Math.abs(res.data.time.hour) +" hours " + Math.abs(res.data.time.minute)+" minutes"
            setSnackbarTitle(timerMsg);
            setOpen(true);
          }
        } else {
          setOtp(false)
          setOpen(true);
          setSeverity("error");
          setSnackbarTitle(
            "Unable to connect the server, Please try again"
          );
        }
      }).catch(() => {
        setOtp(false)
        setOpen(true);
        setSeverity("error");
        setSnackbarTitle(
          "Unable to connect the server, Please try again"
        );
      })
    } else {
      if (location.state.type === "register") {
        loginService.getOtpViaCall(mobileNumber).then(
          (res) => {
            if (res) {
              if (res.status === 200) {
                setOtp(false)
                 setResendOtpTime(30)
                setType("error")
              }
            } else {
              setOpen(true);
              setOtp(false)
              setSeverity("error");
              setSnackbarTitle(
                "Unable to connect the server, Please try again"
              );
            }
          }
        ).catch(() => {
          setOpen(true);
          setOtp(false)
          setSeverity("error");
          setSnackbarTitle(
            "Unable to connect the server, Please try again"
          );
        })
      }
    }
  }
  const handleOnSubmit = (value) => {
    setSubmit(true);
    setTimer(false);
    setHelperText();
    setType()
    if (location.state.type === "login" && !location.state.oldUser) {
      let confirmationResult = window.confirmationResult;
      confirmationResult?.confirm(value).then((result,error) => {
        if(result){
        setSubmit(false);
          let user = result.user;
          console.log(user, 'user')
          loginService
          .verifyMobileOtp(location.state.mobile || null, value).then((res) => {
            setResendOtpTime(0)
            if (res) {
              if (res.status === 200) {
                if (res.data.message === "OTP_VERIFIED") {
                  props.setToken({ token: res.data.Jwttoken.token });

                  cookies.set("token", res.data.Jwttoken.token, {
                    path: "/",
                    expires: new Date(
                      new Date().getTime() + 60 * 60 * 1000 * 24 * 180
                    ),
                  });
                  setUserInfo();
                  setTimeout(() => {
                    navigate("/home");
                  }, 1000);
                }
              } else if (res.status === 400) {
                if (res.data.message === "INVALID_OTP") {
                   setSubmitButton(false)
                   setSubmit(false);
                    setHelperText("Invalid OTP");
                    setType("error")
                    setValueColor(2);
                  // }
                }
              } else if (res.status === 401) {
                if (res.data.message === "ACCOUNT_BLOCKED") {
                  setValueColor(2);
                  setSubmit(false);
                  setSeverity("error");
                  let timerMsg = "Account blocked, please try after "+ Math.abs(res.data.time.hour) +" hours " + Math.abs(res.data.time.minute)+" minutes"
                  setSnackbarTitle(timerMsg);
                  setOpen(true);
                  setSubmitButton(false)
                }
              } else {
                setSubmit(false);
                setSeverity("error");
                setSnackbarTitle("Unable to connect the server, please try again");
                setOpen(true);
                setSubmitButton(false)
              }
            }
          });
        }else{
          setSubmit(false);
          setSubmitButton(false)
          console.log(error, 'error1');
        }
      }).catch((error)=>{
        setSubmitButton(false)
        setSubmit(false);
        setHelperText("Invalid OTP");
        setType("error")
        setValueColor(2);
      })
    
    }
    else if(location.state.type === "register" || location.state.oldUser)  {   
        const data = {
          mobile_number: location != undefined ? location.state != undefined ? location.state.mobile : null : null,
          otp: value,
          change_email: location.state.oldUser,
          id: location.state.oldUser && location?.state?.userId,
          email: location.state.oldUser && location?.state?.email,
          code: location.state.oldUser && location?.state?.code
        };
        let confirmationResult = window.confirmationResult;
        confirmationResult?.confirm(value).then((result,error) => {
     if(result){
      RegisterService.verifyMobileOtp(data).then((res) => {
        setResendOtpTime(0)
        if (res.data.message === "OTP_VERIFIED") {
          setTimeout(() => {
             navigate("/type", {
               replace: true,
               state: location != undefined ? location.state : null,
             });
          }, 500);
        } else if (res.data.message === "OTP_VERIFIED. and Data Updated.") {
          navigate('/home')
          props.setToken({ token: res.data.token.token });
          cookies.set("token", res.data.token.token, {
            path: "/",
            expires: new Date(
              new Date().getTime() + 60 * 60 * 1000 * 24 * 180
            ),
          });
          setUserInfo();
          setTimeout(() => {
            navigate('/home')
         }, 1000);
        }else if (res.data.message === "INVALID_OTP") {
          setSubmit(false);
          setSubmitButton(false)
            setHelperText("Invalid OTP");
            setType("error")
            setValueColor(2);
          // }
        } else {
        setSubmit(false);
        setSubmitButton(false)
          setSeverity("error");
          setSnackbarTitle("Something went wrong");
          setOpen(true);
        }
      });
     }
    }).catch((error)=> {
      setSubmit(false);
      setSubmitButton(false)
        setHelperText("Invalid OTP");
        setType("error")
        setValueColor(2);
    })
 
    }
  };


  const navigateLogin = () => {
    if (location.state.type === "register") {
      navigate("/signupphone",
        {
          replace: true,
          state: location.state,
        });
    } else {
      navigate("/signupphone", {
        replace: true,
        state: { type: "login" ,phone:location.state.phone, ccode:location.state.ccode},
      });
    }
  };

  useEffect(() => {
    localStorage.setItem("internalTeam", true);
},[])
  return (
    <>
      <Page>
        <DisableRightClick>
        <Box className={classes.boxcontainer}>
          <Notification snackbarTitle={snackbarTitle} open={open} setOpen={setOpen} type={severity} />
          <Container >
            <Grid className={classes.container} spacing={1}>
              <Grid>
                <Typography className={classes.verifyText}>
                  Verify your phone number
                </Typography>
                <Grid mb={2.5} style={{ display: "flex" }}>
                  <Typography className={classes.verfySubText}>
                   {location != undefined ? location.state != undefined ? location.state.mobile : null : null}
                  </Typography>
                  <CreateIcon
                    style={{ color: "#8264E5" , fontSize:"20px", padding:"0 4px", boxSizing:"unset"}}
                    onClick={navigateLogin}
                  />
                </Grid>
              </Grid>

              <Formik
                initialValues={{
                  otp: "",
                  oldUser:'1'
                }}
                validateOnChange={true}
                validateOnBlur={true}
                validationSchema={Yup.object().shape({
                  otp: Yup.string()
                    .min(0)
                    .test("length", "Must be exactly 6 digits", (val) => {
                      return val && val.toString().length === 6;
                    }),
                })}
                onSubmit={(values) => {
                  setSubmit(true);
                  setSubmitButton(true)
                  handleOnSubmit(values.otp);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <Grid>
                      <Textfield
                        valueColor={valueColor}
                        otp={values.otp.length>1 &&  true}
                        placeholder={"OTP"}
                        type="tel"
                        inputmode="decimal"
                        inputProps={{ maxLength: 6 }}
                        onChange={(e) => {
                          setFieldValue('otp', e.target.value)
                          setValueColor();
                          if(e.target.value.length === 6){
                            setSubmit(true);
                            setSubmitButton(true)
                            handleOnSubmit(e.target.value);
                          }
                        }}
                        value={values.otp}
                        error={Boolean(touched.otp && errors.otp)}
                        helperText={touched.otp && errors.otp}
                        name="otp"
                        id="otp"
                        onInput={(e) => {
                          setOpen(false)
                          setHelperText();
                          setType()
                          e.target.value = e.target.value
                            .toString()
                            .replace(/[^0-9]/g, "");
                          if (e.target.value.length === 6) {
                            setSubmitButton(false)
                          } else {
                            setSubmitButton(true)
                          }

                        }}
                      />
                     {timer?
                       <HelperText helperText={timerText} type={null} />: helperText ?
                       <HelperText helperText={helperText} type={type} /> : null}
                      <Grid style={{ display: "flex" }}>
                          <OtpButton
                               disabled={timerText|| otp}
                               handleOtp={()=>{
                                handleOtp()
                                setFieldValue("otp", '')
                              }}
                            value={"Resend OTP"}
                          />
                           {/* <OtpButton
                           disabled={timerText || otp}
                           handleOtp={()=>{
                            handleOtpViaCall()
                            setFieldValue("otp", '')
                          }}
                           value={"Get OTP via call"}
                         /> */}
                      </Grid>
                    </Grid>
                    <Grid>
                      <Buttons
                        submitButton={submitButton}
                        value={ location.state?.type === "register" ?
                            "VERIFY" : "LOGIN"
                        }
                        type="submit"
                        value1={values.otp}
                      animation={submit}
                      />
                    </Grid>
                  </form>
                )}
              </Formik>
            </Grid>
          </Container>
        </Box>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submit}
      >
      </Backdrop>
      <div id="recaptcha"></div>
      </DisableRightClick>
      </Page>
    </>
  );
};

const dispatchToProps = (dispatch) => ({
  setToken: (token) => dispatch(setToken(token)),
  loginSuccess: (auth) => dispatch(loginSuccess(auth)),
});

export default connect(null, dispatchToProps)(OtpPhone);
